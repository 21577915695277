import React, { useEffect } from 'react'
import tickicon from '../assets/evolvex/images/tickicon.png'
import CopyToClipboard from "react-copy-to-clipboard";
import { toast } from 'react-toastify';
import Aos from 'aos';
import copyImage from '../assets/evolvex/images/copy.png'
import config from "../views/config/config";
import { Link } from 'react-router-dom';


const DaosCard = (props) => {

    const { id, Profile, bannerImage, DisplayName, address, CustomUrl, WalletAddress } = props.item


    useEffect(() => {
        Aos.init();
    }, [])
    return (
        <Link to={`/profile/${CustomUrl}`} >
        <div
            className='ev-daoscard'
            data-aos="fade-up"
            data-aos-offset="300">
            <div className='ev-daoscard__imgwrapper'>
                <img src={Profile
                    ? `${config.IMG_URL}/user/${WalletAddress}/profile/${Profile}`
                    : config.profile} alt={DisplayName} />
            </div>

            <div className='ev-daos__details mt-4 d-flex flex-column align-items-center'>
                <div className='ev-creatorcard__name'>
                    <h5>{DisplayName}</h5>
                    <div className='ev-verified'>
                        <img src={tickicon} alt='copy' />
                    </div>
                </div>
                <div className='ev-creatorcard__address'>
                <p>{WalletAddress.slice(0, 10) + '...'}</p>
        <CopyToClipboard
            text={WalletAddress}
            onCopy={() =>
                toast.success("Address Copied", { autoClose: 1000 })
            } >

            <img src={copyImage} alt='copy' />

            {/* <i className="fas fa-sticky-note notes_fa cur_pointer"></i> */}
        </CopyToClipboard>
                    {/* <button className='ev-creatorcard__button--copy'>
                        <img src={copyImage} alt='copy' />
                    </button> */}
                </div>
            </div>

        </div>
        </Link>

    )
}

export default DaosCard