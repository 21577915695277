import React, { useState, useEffect, useRef } from "react";
import Card from "./Card.js";
import Header from "../separate/Header.js";
import Footer from "../separate/Footer.js";
import { NavLink, Link, useParams } from "react-router-dom";
import { useSelector } from "react-redux";
import { Token_List_Func } from "../actions/axioss/nft.axios";
import Button from "react-bootstrap/esm/Button.js";
import NoData from "./seperatemodals/nodata.jsx";
import { MdKeyboardArrowRight } from "react-icons/md";
import NFTcard from "../ cards/NFTcard.js";
import config from "./config/config";
import { getCmsContent } from "../actions/axioss/cms.axios";
import auction1 from "../assets/evolvex/images/auction1.gif";
import auction2 from "../assets/evolvex/images/auction2.gif";
import auction3 from "../assets/evolvex/images/auction3.png";
import auction4 from "../assets/evolvex/images/auction4.png";
import auction5 from "../assets/evolvex/images/auction5.png";
import auction6 from "../assets/evolvex/images/auction6.png";
import auction7 from "../assets/evolvex/images/auction7.png";
import auction8 from "../assets/evolvex/images/auction8.png";
import auction9 from "../assets/evolvex/images/auction9.png";
import auction10 from "../assets/evolvex/images/auction10.png";
import EvFooter from "../separate/EvFooter.js";

import launchBanner from "../assets/evolvex/images/launchbanner.png";

import bannerImage from "../assets/evolvex/images/launchbanner.png";

const hotAuctionCardData = [
  {
    id: 1,
    profileOne: auction9,
    profileTwo: auction10,
    banner: auction1,
  },
  {
    id: 2,
    profileOne: auction9,
    profileTwo: auction10,
    banner: auction2,
  },
  {
    id: 3,
    profileOne: auction9,
    profileTwo: auction10,
    banner: auction3,
  },
  {
    id: 4,
    profileOne: auction9,
    profileTwo: auction10,
    banner: auction4,
  },
  {
    id: 5,
    profileOne: auction9,
    profileTwo: auction10,
    banner: auction5,
  },
  {
    id: 6,
    profileOne: auction9,
    profileTwo: auction10,
    banner: auction6,
  },
  {
    id: 7,
    profileOne: auction9,
    profileTwo: auction10,
    banner: auction7,
  },
  {
    id: 8,
    profileOne: auction9,
    profileTwo: auction10,
    banner: auction8,
  },
];

export default function Launchpad() {
  const [loadingstate, setLoadingstate] = useState(true);
  const [category, setCategory] = React.useState(1);
  const [show, setShow] = React.useState(false);
  const [filter, setFilter] = useState("recentcreated");
  var [filtershow, setFiltershow] = useState("Recently Created");
  const [homecontent, setHomecontent] = useState([]);
  const { Categorys } = useSelector((state) => state.LoginReducer);
  const { payload } = useSelector((state) => state.LoginReducer.User);
  const { Category } = useParams();

  console.log("category", payload, Category);

  const [TabName, SetTabName] = useState("All");
  var LikeForwardRef = useRef();
  const [LikedTokenList, setLikedTokenList] = useState([]);
  const [Tokens, SetTokens] = useState({
    All: { loader: true, page: 1, list: [] },
  });
  const Tabname = (newValue) => {
    SetTabName(newValue);
  };
  useEffect(() => {
    if (Category) {
      if (Category !== TabName) {
        SetTabName(Category);
        Explore(1, Category);
      }
    } else {
      Explore();
    }
  }, [Category]);

  useEffect(() => {
    // if (typeof Tokens[TabName] == "undefined") {
    if (
      typeof Tokens[TabName] == "undefined" ||
      Tokens[TabName].filter !== filter
    ) {
      Tokens[TabName] = { page: 1, list: [], loader: false };
      SetTokens(Tokens);
      Explore(1, TabName);
    }
  }, [TabName, filter]);
  useEffect(() => {
    Getandselldata();
  }, []);

  const Explore = async (data, tab) => {
    setLoadingstate(true);

    var page = data ? data : Tokens[TabName]?.page;
    var SendDATA = {
      TabName: "",
      limit: 12,
      ProfileUrl: payload?.ProfileUrl ? payload.ProfileUrl : "",
      page: page ?? 1,
      from: "LaunchPad",
      filter: filter,
    };
    let Resp = await Token_List_Func(SendDATA);
    console.log("Check datad", Resp);
    if (Resp?.data) {
      SetTokens({
        ...Tokens,
        ...{
          [TabName]: {
            list: [...Tokens[TabName].list, ...Resp.data],

            loader:
              Resp.data.length == 0 ||
              (SendDATA.TabName == "usercollection" && Resp.cursor == null)
                ? false
                : true,
            page: Tokens[TabName].page,
            filter: filter,
          },
        },
      });
      setLoadingstate(false);
    } else
      SetTokens({
        ...Tokens,
        ...{
          [TabName]: {
            list: Tokens[TabName].list,
            loader: false,
            page: Tokens[TabName].page,
            filter: filter,
          },
        },
      });
    setLoadingstate(false);
  };
  const LoadMore = () => {
    Tokens[TabName].page = Tokens[TabName].page + 1;
    SetTokens(Tokens);
    Explore(Tokens[TabName].page);
  };
  function LikeList(data) {
    setLikedTokenList(data);
  }

  // useEffect(() => {
  //   if (Category) {
  //     console.log("VALUE",Category);
  //     SetTabName(Category)
  //     Explore(1, Category)
  //   }
  //   else {
  //     Explore();
  //   }
  // }, [Category]);

  console.log("tokeokokeokoekoeke", Tokens);

  const Getandselldata = async () => {
    var resp = await getCmsContent("launchpad");
    console.log("cmslistdfgfd", resp.data);
    if (resp?.status) setHomecontent(resp.data);
  };

  return (
    <>
      <Header />
      <div className="explore">
        <section className="tf-page-title" data-aos="fade-right">
          {/* <div className="tf-container"> */}
          <div className="container container-width">
            <div className="ev-launch__banner">
              {homecontent?.img?.[0] ? (
                // <img
                //   src={`${config.IMG_URL}cmsimg/${homecontent?.img?.[0]}`}
                //   className="ev-launch__banner--img"
                //   alt=""
                // />
                <img src={`${config.IMG_URL}/cmsimg/${homecontent?.img?.[0]}`} alt="" className="ev-launch__banner--img" />
              ) : (
                <img src={launchBanner} alt="" className="ev-launch__banner--img"/>
              )}
              <div className="row justify-content-center h-100">
                <div className="col-12 col-md-8 col-xl-6 d-flex flex-column justify-content-center align-items-center h-100">
                  {console.log("homecontent", homecontent?.img?.[0])}

                  <h5 className="title">
                    {homecontent.question ? homecontent.question : "Launchpad"}
                  </h5>

                  {/* The character length should be 230 or less for description */}

                  <p
                    className="description d-none d-md-block"
                    dangerouslySetInnerHTML={{
                      __html: homecontent.answer
                        ? homecontent.answer
                        : "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat.",
                    }}
                  ></p>
                </div>
              </div>
            </div>

            <div className="row">
              <div className="col-md-12">
                <ul className="breadcrumbs">
                  <li>
                    <NavLink to="/">
                      Home <MdKeyboardArrowRight />
                    </NavLink>
                  </li>
                  <li>Launchpad</li>
                </ul>
                <h4 className="page-title-heading">Launchpad</h4>
              </div>
            </div>
          </div>
        </section>
        <section
          className="tf-live-auction explore tf-filter exploreinnersec"
          data-aos="fade-left"
        >
          {/* <div className="tf-container"> */}

          <div className="container container-width">
            {/* category and subcategory section - start */}

            {/* <div className="row ">
                            <div className="col-md-12">
                                <div className="top-menu ev-explore__top-menu wow fadeInUp explore_category_section" data-aos="fade-up">
                                    <ul className="filter-menu">
                                        <Link to="/explore">
                                            <li
                                                className={TabName == "All" ? "active" : ""}
                                                onClick={() => Tabname("All")}
                                            >
                                                All
                                            </li>
                                        </Link>
                                        {Categorys.map((item) => {
                                            return (
                                                <Link to="/explore">
                                                    <li
                                                        className={TabName == item.label ? "active" : ""}
                                                        onClick={() => Tabname(item.label)}
                                                    >
                                                        <a>{item.label}</a>
                                                    </li>
                                                </Link>
                                            );
                                        })}
                                    </ul>
                                    <div id="item_category2" className="dropdown">
                                        <Link to="#" className="btn-selector nolink allshow">
                                            {filtershow}
                                        </Link>
                                        <ul className="show">
                                            <li
                                                onClick={() => {
                                                    setFilter("recentlisted");
                                                    setFiltershow("Recently Listed");
                                                }}
                                            >
                                                <span>Recently Listed</span>
                                            </li>
                                            <li
                                                className="active"
                                                onClick={() => {
                                                    setFilter("recentcreated");
                                                    setFiltershow("Recently Created");
                                                }}
                                            >
                                                <span>Recently Created</span>
                                            </li>
                                            <li
                                                onClick={() => {
                                                    setFilter("recentsold");
                                                    setFiltershow("Recently Sold");
                                                }}
                                            >
                                                <span>Recently Sold</span>
                                            </li>
                                            <li
                                                onClick={() => {
                                                    setFilter("PriceHighToLow");
                                                    setFiltershow("High to Low");
                                                }}
                                            >
                                                <span>High to Low</span>
                                            </li>
                                            <li
                                                onClick={() => {
                                                    setFilter("PriceLowToHigh");
                                                    setFiltershow("Low to High");
                                                }}
                                            >
                                                <span>Low to High</span>
                                            </li>
                                            <li
                                                onClick={() => {
                                                    setFilter("oldest");
                                                    setFiltershow("Oldest");
                                                }}
                                            >
                                                <span>Oldest</span>
                                            </li>
                                        </ul>
                                    </div>
                                </div>
                            </div>
                            <div className="col-md-12 py-4">
                                <div className="ev-explore__subcategory">
                                    <button className="ev-explore__subcat-button active">Sub Category 1</button>
                                    <button className="ev-explore__subcat-button">Sub Category 2</button>
                                    <button className="ev-explore__subcat-button">Sub Category 3</button>
                                    <button className="ev-explore__subcat-button">Sub Category 4</button>


                                </div>

                            </div>
                        </div> */}

            {/* category and subcategory section - end */}

            <div className="tf-filter-container row " data-aos="fade-up">
              {/* {category == 1 ? */}
              {/* {console.log('likrreeeeexple',LikeForwardRef,setLikedTokenList,LikedTokenList)} */}
              {loadingstate == true ? (
                <>
                  <div className="text-centre">
                    <h3>Loading...</h3>
                    {/* {/ <p>Come back soon ! or try to browse something for you on our marketplace</p> /} */}
                    {/* <p>Nothing for ReSale at this time</p> */}
                    <div className="load-more">
                      {/* <Link to='/'><Button type="type" className="btn-load browse_marketplace" disableRipple>Browse Marketplace</Button></Link> */}
                    </div>
                  </div>
                </>
              ) : // start of old code

              // TabName && Tokens[TabName]?.list.length > 0 ? (
              //   Tokens[TabName]?.list.map((item, index) => (
              //     <div className="col-xl-3 col-lg-4 col-md-6 col-sm-6 tf-loadmore 3d cyber">
              //       {console.log("datainexplore", item)}
              //       <Card
              //         product={item}
              //         index={index}
              //         LikeList={LikeList}
              //         LikedTokenList={LikedTokenList}
              //         setLikedTokenList={setLikedTokenList}
              //         LikeForwardRef={LikeForwardRef}
              //       />
              //     </div>
              //   ))
              // )

              TabName && Tokens[TabName]?.list.length > 0 ? (
                Tokens[TabName]?.list.map((item, index) => (
                  // <div className="col-xl-3 col-lg-4 col-md-6 col-sm-6 tf-loadmore 3d cyber">
                  <div className="d-flex justify-content-center justify-content-lg-start col-md-6 col-lg-4 col-xl-3 mb-5">
                    {console.log("datainexplore", item)}
                    <Card
                      product={item}
                      index={index}
                      LikeList={LikeList}
                      LikedTokenList={LikedTokenList}
                      setLikedTokenList={setLikedTokenList}
                      LikeForwardRef={LikeForwardRef}
                      Status="Launchpad"
                    />
                  </div>
                ))
              ) : (
                // start of evolvex code

                // TabName && Tokens[TabName]?.list.length > 0 ? (
                //   Tokens[TabName]?.list.map((item, index) => (
                //     //     <div className="col-xl-3 col-lg-4 col-md-6 col-sm-6 tf-loadmore 3d cyber">
                //     hotAuctionCardData.map((item) => (
                //       <div className='col-xl-3 col-lg-4 col-md-6 col-sm-6 tf-loadmore 3d cyber mb-5'>
                //         <NFTcard item={item} />
                //       </div>
                //     ))
                //   ))
                // )

                // end of evolvex code

                <NoData />
              )}
            </div>
            {/* {console.log('gfbvfbfb',Tokens,Tokens[TabName]?.loader)} */}
            {Tokens[TabName]?.loader && (
              <div className="d-flex justify-content-center">
                {/* <div className="btn-loadmore mt17">
                  <Button className="tf-button loadmore" onClick={LoadMore}>
                    Load More
                  </Button>
                </div> */}
                <button onClick={LoadMore} className=" ev_bannerBlueBtn my-5">
                  Load More
                </button>
              </div>
            )}
          </div>
        </section>
      </div>
      {/* <Footer /> */}
      <EvFooter />
    </>
  );
}
