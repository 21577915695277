
import React, { useContext, useEffect, useState } from "react";
import "../assets/js/common.js";
import { Link, NavLink, useNavigate } from "react-router-dom";
import { useLocation } from "react-router-dom";
import $ from "jquery";
import Copy from "../assets/images/svg/copy.svg";
import Profile from "../assets/images/profileicon.png";
import WalletIcon from "../assets/images/svg/wallet.svg";
import LogoutIcon from "../assets/images/svg/logout.svg";
import Accordion from 'react-bootstrap/Accordion';
import { toast } from 'react-toastify';
import { connectWallet, getServiceFees } from '../views/hooks/useWallet';
import { isEmpty } from '../actions/common';
import CopyToClipboard from "react-copy-to-clipboard";
import { IoMdClose } from "react-icons/io";
import { GiHamburgerMenu } from "react-icons/gi";

import { RxPerson } from 'react-icons/rx';

import { userRegister, SearchAction, GetUserCookieToken } from '../actions/axioss/user.axios'
import { GetNftCookieToken } from '../actions/axioss/nft.axios'

import { Category, Currency, USDPRICE, TOKENPRICE } from '../actions/axioss/cms.axios'
import { useDispatch, useSelector } from 'react-redux';
import Config from "../views/config/config";
import { address_showing, copydata } from "../actions/common";

import users from "../assets/images/user.png";
import noimg from "../assets/images/noimage.png"
import { network } from "../views/config/network.js";
import { config } from "process";
import BeforeloginEditProfile from "../views/BeforeloginEditProfile.js";
import { GlobalContext } from "../context/context.js";
import logo from '../assets/evolvex/images/logo.png'
import logoBlack from '../assets/evolvex/images/newblacklogo.png'



export default function Header(props) {


    const { enableHeader, enableHeaderLogo, setEnableHeader, navbarActive, handleShowMobileSidebar, showMobileSidebar, handleCloseMobileSidebar } = useContext(GlobalContext);

    const [menu, setMenu] = React.useState(false);
    const [arrow, setArrow] = React.useState(false);
    const [categorys, setCategory] = React.useState([]);

    const [Searchdata, SetSearch] = React.useState(null);

    const { Categorys } = useSelector((state) => state.LoginReducer);


    const [val, Setval] = useState("");

    const push = useNavigate()
    const [updatePage, setUpdatePage] = useState()
    const [dataa, setDataa] = useState({})
    let location = useLocation();
    // $(window).scroll(function () {
    //     var scroll = $(window).scrollTop();   
    //     if ($(this).scrollTop()) {
    //       $("header").addClass("fixedTop");
    //     } else {
    //       $("header").removeClass("fixedTop");
    //     }
    //     if(scroll >= 100){
    //         $(".fixedTop").addClass('scroll');
    //         $("#scroll-top").addClass('show');
    //     }
    //     else{
    //         $(".fixedTop").removeClass('scroll');
    //         $("#scroll-top").removeClass('show');
    //     }
    //   });
    React.useEffect(() => {
        window.scrollTo(0, 0)
    }, [])
    // client changed default theme dark
    // if (localStorage.theme) {
    //     localStorage.setItem("theme", localStorage.theme);
    //     var themeChange = localStorage.getItem("theme");
    //     if (localStorage.theme == "is_dark") {
    //         document.body.classList.remove("is_dark"); 

    //     }
    //     else{
    //         document.body.classList.remove("is_dark"); 

    //     }
    //     document.body.classList.add(localStorage.theme);
    //   } else {
    //     localStorage.setItem("theme", "is_dark"); 

    //     var themeChange = localStorage.getItem("theme");
    //     document.body.classList.remove("is_dark"); 

    //     document.body.classList.add("is_light");
    //   }


    const switchTheme = () => {
        if (localStorage.theme == "is_light") {
            localStorage.setItem("theme", "is_dark");
            document.body.classList.remove("is_dark");
            document.body.classList.add("is_light");
        }
        else {
            localStorage.setItem("theme", "is_light");
            document.body.classList.remove("is_dark");
            document.body.classList.add("is_light");
        }
    }

    const dispatch = useDispatch();
    const wallet = useSelector(state => state.LoginReducer.AccountDetails);
    console.log("Walladdress", wallet);
    const { Network } = useSelector(
        (state) => state.LoginReducer
    );
    const [searchbottom, setSearchBottom] = useState(false)
    const { payload, token } = useSelector(state => state.LoginReducer.User)
    console.log("User details", payload, wallet, Network)
    const [reconnect, setReconnect] = useState(true)
    useEffect(() => {
        console.log("Reconn", reconnect, payload, localStorage.getItem("walletConnectType"), wallet?.accountAddress);
        if (localStorage.getItem("walletConnectType") && wallet?.accountAddress == '' && reconnect) {
            initialConnectWallet(localStorage.getItem("walletConnectType"))

            setReconnect(false)
        }
        // if(wallet?.accountAddress){
        getInitialSeviceFee();
        // }
        Categorys1();
        CurrencyList();

    }, [Network]);

    useEffect(() => {
        if (window.solana) {
            window.solana?.on("accountChanged", handleAccountChange);
            window.solana.on("chainChanged", handleAccountChange);
            return () => {
                window.solana?.removeListener("accountChanged", handleAccountChange);
                window.solana?.removeListener("chainChanged", handleAccountChange);
            };
        }
    }, [])

    window.addEventListener('load', function () {
        // Add a click event listener to the body
        document.body.addEventListener('click', function () {
            // Code to execute when the body is clicked

            Setval(''); SetSearch(null)
        });


    });
    const Categorys1 = async () => {

        let Resp = await Category();

        if (Resp?.data) {
            var sendda = [];
            var data = (Resp.data || []).map((item) => {

                // console.log('mapppppp',item);
                if (item.hideShow !== "hidden") {
                    sendda.push({
                        label: item.name,
                        value: item.name,
                        description: item.description,
                        BulkSubCategory:item.BulkSubCategory
                    });
                }

            });
            //   setCategory(sendda)
            dispatch({
                type: "Register_Section",
                Register_Section: {
                    Categorys: sendda,
                },
            });
        }
    };
    const CurrencyList = async () => {
        let Resp = await Currency();
        console.log("Resp@123currency", Resp?.msg);

        if (Resp?.msg) {
            var sen = [];
            var bnb = Resp?.msg.filter((item) => item.ChainId == Config.BNBCHAIN)
            var eth = Resp?.msg.filter((item) => item.ChainId == Config.ETHCHAIN)
            console.log('aaaaaaasssssssssssssssssssssssssaa', sen, bnb, eth)
            var bnbdatas = await Promise.all(
                bnb[0]?.CurrencyDetails ||
                []?.map(async (data) => {
                    if (data.label == "SOL" || data.label == "ETH")
                        var USD = await USDPRICE(data.label);
                    else var USD = await TOKENPRICE(data.address);
                    sen.push({
                        value: data.value,
                        label: data.label,
                        address: data.address,
                        usd: USD ? USD : 0,
                        decimal: data.decimal,
                    });
                })
            );
            var ethdatas = await Promise.all(
                eth[0]?.CurrencyDetails ||
                []?.map(async (data) => {
                    if (data.label == "SOL" || data.label == "ETH")
                        var USD = await USDPRICE(data.label);
                    else var USD = await TOKENPRICE(data.address);
                    sen.push({
                        value: data.value,
                        label: data.label,
                        address: data.address,
                        usd: USD ? USD : 0,
                        decimal: data.decimal,
                    });
                })
            );
            console.log('currencydats', Network, sen, bnbdatas, ethdatas, Network == Config.BNBCHAIN ? bnbdatas : ethdatas)
            dispatch({
                type: "Register_Section",
                Register_Section: {
                    currency: Network == Config.BNBCHAIN ? bnbdatas : ethdatas,
                    //   ethcurrency : ethdatas.length > 0 ? ethdatas : sen
                },
            });
        }
    };

    const handleAccountChange = (...args) => {
        console.log("helllllllllo");
        const id = toast.success("Wallet Connecting...", { autoClose: 1000, isLoading: false })
        toast.update(id, { autoClose: 1000, isLoading: false, })
        initialConnectWallet(localStorage.walletConnectType);
    }
    const initialConnectWallet = async (type, chain) => {

        const id = ("Wallet Connecting...", { closeButton: true, closeOnClick: true })
        console.log("ennanadkkuthu", type);
        // if(!localStorage.getItem("accountInfo")){
        var accountDetails = await connectWallet(type)
        console.log("accountDetails", accountDetails)

        if (!isEmpty(accountDetails)) {
            // debugger

            // var chainid = await accountDetails.web3.eth.getChainId();
            // console.log("ACCOUNTDETAILSkkkkk", accountDetails, chainid)
            var resp = await setWalletAddress('InitialConnect', accountDetails.accountAddress, type, id)
            console.log("resppppppppp", resp)
            if (resp?.success == 'success') {
                toast.update(id, { render: resp.msg, type: resp.success, autoClose: 1000, isLoading: false, })

                // dispatch({
                //     type: "Network_section",
                //     Network_section: {
                //         Network: chainid
                //     },
                // })


                dispatch({
                    type: "Account_Section",
                    Account_Section: { AccountDetails: accountDetails }
                })
                //console.log("jidioiedd", chainid);

            }
            // else {
            //     toast.update(id, { render: "Connect Wallet", type: 'error', autoClose: 1000, isLoading: false, })
            // }

        }
        // }
        // else toast.update(id,{render : "Try Again" , type:'error',autoClose:1000, isLoading: false,})
    }
    const setWalletAddress = async (type, walletAddress, walletType, id) => {
        if (walletAddress) {
            var NewMethod = {
                Type: type,
                WalletAddress: walletAddress,
                WalletType: walletType,
            };
            //   console.log("Resp.data1",NewMethod);
            let Resp = await userRegister(NewMethod);
            console.log("Resp.data", Resp?.data);
            if (Resp?.already && Resp?.data?.DisplayName != "") {
                if (Resp?.success == 'success') {
                    dispatch({
                        type: 'Register_Section',
                        Register_Section: {
                            User: {
                                payload: Resp?.data,
                                token: Resp?.token ? Resp.token : token
                            }
                        }
                    })
                    document.cookie = 'token' + "=" + Resp?.token + ";" + ";path=/";
                    GetNftCookieToken();
                    GetUserCookieToken();
                    return Resp
                }
                else return Resp
            } else {
                toast.update(id, { render: "Create Profile First", type: 'success', autoClose: 1000, isLoading: false, })
                if (!isEmpty(payload)) {
                    dispatch({
                        type: 'Register_Section',
                        Register_Section: {
                            User: {
                                token: "",
                                payload: null
                            }
                        }
                    })
                }
                localStorage.removeItem("accountInfo")
                localStorage.removeItem("walletConnectType")
                dispatch({
                    type: 'Account_Section',
                    Account_Section: {
                        AccountDetails: {
                            accountAddress: "",
                            tokenBalance: 0,
                            coinBalance: 0
                        }
                    }
                })
                push(`/createProfile/${walletAddress}`)
                // Resp.data.initial = true
                // setDataa(Resp.data)
                // setUpdatePage(true)

            }
        }
        else return { success: 'error', msg: 'No Address Detected.. Check Your Wallet' }

    }
    const walletDisconnect = async () => {
        // localStorage.removeItem("accountInfo")
        // localStorage.removeItem("walletConnectType")
        localStorage.clear()
        dispatch({
            type: 'Account_Section',
            Account_Section: {
                AccountDetails: {
                    accountAddress: "",
                    tokenBalance: 0,
                    coinBalance: 0
                }
            }
        })
        push("/")
        // window.location.reload();
        document.cookie = 'token' + "=" + "" + ";" + ";path=/";
        GetNftCookieToken();
        GetUserCookieToken();
    }
    const getInitialSeviceFee = async () => {
        var fees = await getServiceFees();
        console.log("getServiceFees", fees);
        if (fees) {
            dispatch({
                type: 'ServiceFees',
                ServiceFees_Section: {
                    ServiceFees: fees,
                },
            });
        }
    };
    const click = async (table) => {
        push("/explore/" + table)
    }


    const OnChange = async (value) => {
        // console.log("vallllllllllll",value);
        if (value) {
            Setval(value);
            var Resp = await SearchAction({

                keyword: value,
                limit: 3,
                page: 1,
                from: "search",
            });
            // console.log("response", Resp);
            if (Resp?.success === "success") {
                SetSearch(Resp);
            }
            else {
                SetSearch(null)
            }
        }
        else {
            SetSearch(null)
            Setval('')
        }
    };

    const Search = () => {
        if (window.location.pathname.includes('search')) {
            SetSearch(null)
            Setval('')
        }

    }

    { console.log("netettetete", Network) }

    console.log('locationnn', location.pathname)

    // useEffect(() => {

    //     if (location.pathname.includes('/')) {
    //         setEnableHeader(true)
    //     }

    // }, [location.pathname])

    useEffect(() => {
        if (location.pathname !== '/') {
            if (!enableHeaderLogo) {
                setEnableHeader(true)
            }
        }

    }, [location.pathname])

    console.log('loationnn', location.pathname)


    return (
        <>
            {/* {enableHeader && */}
            {enableHeader &&

                <>
                    <header className={`ev-header ${location.pathname !== '/' && 'innerev-header'} ${location.pathname === '/' && !navbarActive ? 'ev-header__white-menus' : ""} ${navbarActive ? "ev-header__fixed--animation" : ""} ${location.pathname === '/connect' && 'connectpageheader'}`}>
                        {menu == true ?
                            (<nav id="main-nav-mobi" className="main-nav d-block d-xl-none">
                                <ul id="menu-primary-menu" className="menu">
                                    {/* <li className="menu-item">
                                            <NavLink to="/">Home</NavLink>
                                        </li> */}
                                    <li className={`${navbarActive ? "animation_active" : 'animation_inactive'} menu-item search-item`}>
                                        <form action="#">
                                            <div className="search-form d-flex justify-content-between align-items-center">
                                                <input type="text" placeholder="Search" required="" onChange={(e) => { OnChange(e.target.value); setSearchBottom(true) }} autoComplete="off" />
                                                <Link className="btn-search">
                                                    {!val && <i className="icon-fl-search-filled"></i>}
                                                    {val && <i className="fa fa-times" onClick={() => { Setval(''); SetSearch(null) }}></i>}
                                                    {/* <i className="fa fa-times"></i> */}
                                                </Link>
                                                <div className={searchbottom ? "search-model" : "search-model kr_align"} >
                                                    {val && (
                                                        <div className="contentTop" id="smc-modal">
                                                            {Searchdata?.token?.data?.length > 0 ||
                                                                Searchdata?.user?.msg?.length > 0 || Searchdata?.collection?.msg?.length > 0 ? (<>
                                                                    {Searchdata?.collection?.data?.length > 0 && (
                                                                        <div className="content">
                                                                            {/* <h6>Collections</h6> */}
                                                                            {Searchdata?.collection?.data?.map(
                                                                                (value) => (<>
                                                                                    {/* <div className="d-flex align-items-center justify-content-start pt-4 searchCursor" onClick={() => {
                                                                        Setval("");
                                                                      
                                                                    }}
                                                                    >
                                                                       <div className="">
                                                                       <img
                                                                                        src={
                                                                                            value.CollectionProfileImage
                                                                                                ? `${Config.IMG_URL}/collection/${value.CollectionSymbol}/${value.CollectionProfileImage}`
                                                                                                : require("../assets/images/noimage.png")
                                                                                        }
                                                                                        
                                                                                     alt="Search" className="img-fluid"/>
                                                                       </div>
                                                                       <div className="ml-3">
                                                                        <p>{value.CollectionName}</p>
                                                                       
                                                                        
                                                                        </div>
                                                                    </div> */}
                                                                                </>
                                                                                )
                                                                            )}
                                                                        </div>
                                                                    )}
                                                                    {Searchdata.user?.msg?.length > 0 && (

                                                                        <div className="content">
                                                                            <h6>Users</h6>
                                                                            {Searchdata.user?.msg.map(
                                                                                (value) => (
                                                                                    <>
                                                                                        <div className="d-flex align-items-center justify-content-start pt-4 searchCursor"
                                                                                            onClick={() => {
                                                                                                Setval("");
                                                                                                push(
                                                                                                    `/profile/${value.CustomUrl}`
                                                                                                );
                                                                                            }}
                                                                                        >
                                                                                            <div className="">
                                                                                                <img
                                                                                                    src={
                                                                                                        value.Profile
                                                                                                            ? `${Config.IMG_URL}/user/${value.WalletAddress}/profile/${value.Profile}`
                                                                                                            : Config.profile
                                                                                                    }
                                                                                                    className="img"
                                                                                                />
                                                                                            </div>
                                                                                            <div className="ml-3">
                                                                                                <p >
                                                                                                    {value.DisplayName}
                                                                                                </p>
                                                                                                <span></span>
                                                                                            </div>
                                                                                        </div>
                                                                                    </>
                                                                                )
                                                                            )}
                                                                        </div>
                                                                    )}

                                                                    {Searchdata.token?.data?.length > 0 && (
                                                                        <div className="content">
                                                                            <h6>Tokens</h6>
                                                                            {Searchdata.token?.data.map(
                                                                                (value) => (<>
                                                                                    <div className="d-flex align-items-center justify-content-start pt-4 searchCursor"
                                                                                        onClick={() => {
                                                                                            Setval("");
                                                                                            SetSearch();
                                                                                            push(
                                                                                                `/info/${value.CollectionNetwork}/${value.ContractAddress}/${value.NFTOwner}/${value.NFTId}`
                                                                                            );
                                                                                        }}
                                                                                    >
                                                                                        <div className="">
                                                                                            {console.log("COMPRESED", value.CompressedFile)}
                                                                                            <img src={value.CompressedFile.includes('.webp') || value.CompressedFile.includes('.png') ? value.CompressedFile.split(':')[0] == 'https' ? value.CompressedFile : `${Config.IMG_URL}/nft/${value.NFTCreator}/Compressed/NFT/${value.CompressedFile}` : `${Config.IMG_URL}/nft/${value.NFTCreator}/Compressed/NFT_THUMB/${value.CompressedThumbFile}`} alt="Search" className="img-fluid" />
                                                                                        </div>
                                                                                        <div className="ml-3">
                                                                                            <p>  {value.NFTName}</p>
                                                                                            <span></span>
                                                                                        </div>
                                                                                    </div>

                                                                                </>
                                                                                )
                                                                            )}
                                                                        </div>

                                                                    )}
                                                                    <div className="tf-button search-button my-5 d-flex justify-content-center">
                                                                        <Link to={(`/search/${val}`)}>Search</Link>
                                                                        {/* <Link to="/">Search</Link> */}
                                                                    </div>
                                                                </>) : (val === '' ? <></>
                                                                    : <span className="error_msg">
                                                                        No data Found
                                                                    </span>
                                                            )}
                                                        </div>
                                                    )}
                                                    {/* <ul className="sub-menu d-block">
                                                <li className="menu-item "><NavLink to="/explore">3D MODEL</NavLink></li>
                                                <li className="menu-item"><NavLink to="/explore">ANIME/MANGA</NavLink></li>
                                                <li className="menu-item"><NavLink to="/explore">CYBER PUNK</NavLink></li>
                                                <li className="menu-item"><NavLink to="/explore">PIXEL ART</NavLink></li>
                                                <li className="menu-item"><NavLink to="/explore">MUSIC</NavLink></li>
                                                <li className="menu-item"><NavLink to="/explore">2D ARTS</NavLink></li>
                                                <div class="btn-slider "><NavLink class="tf-button style-2" href="/">Search<i class="far fa-long-arrow-right"></i></Link></div>
                                            </ul> */}
                                                </div>
                                            </div>
                                        </form>
                                    </li>
                                    <li className="menu-item menu-item-has-children">
                                        <Accordion defaultActiveKey="0" className="menu-sub">
                                            <Accordion.Item eventKey="1">
                                                <Accordion.Header> <NavLink to="/explore">Explore</NavLink></Accordion.Header>
                                                <Accordion.Body>

                                                    <ul className="sub-menu d-block">
                                                        <li className={"menu-item"} onClick={() => { click("All") }}><Link to="#">All</Link></li>
                                                        {Categorys.map(item => <li className={"menu-item"} onClick={() => { click(item.value) }}><Link to="#">{item.label}</Link></li>)}
                                                    </ul>
                                                </Accordion.Body>
                                            </Accordion.Item>
                                        </Accordion>
                                    </li>
                                    {/* {console.log("locationpath", location.pathname.includes("createProfile"), location.pathname)}
                                    {location.pathname.includes("createProfile") || location.pathname.includes("connect") ? <></> :
                                        <li className="menu-item">
                                            <NavLink to="/create">Create</NavLink>
                                        </li>} */}

                                    <li className="menu-item">
                                        <NavLink to="/faq">FAQ</NavLink>
                                    </li>
                                    <li className="menu-item">
                                                    <NavLink to="/launchpad">Launchpad</NavLink>
                                                </li>
                                                <li className="menu-item">
                                                    <NavLink to="/resale">Resale</NavLink>
                                                </li>
                                    {wallet && wallet.accountAddress ?
                                        <>
                                            <li className="menu-item">
                                                <NavLink to={`/profile/${payload?.CustomUrl}`}>Profile</NavLink>
                                                {/* <NavLink to="/">Profile</NavLink>                                              */}
                                            </li>
                                            <li className="menu-item">
                                                <NavLink to="#" onClick={() => { walletDisconnect() }}>Disconnect</NavLink>
                                                {/* <NavLink to="#" >Disconnect</NavLink>                                      */}
                                            </li>
                                        </>
                                        :
                                        <li className="menu-item">
                                            <NavLink to="/connect">Connect Wallet</NavLink>
                                        </li>
                                    }

                                </ul>
                            </nav>)
                            : (<></>)
                        }
                        {/* <div className="tf-container"> */}
                        <div className={`h-100 container position-relative ${location.pathname !== '/' || navbarActive ? "container-width" : 'ev-container'}`}>

                            <Link to='/' className={`ev-header__fixed-logo ${location.pathname === '/' || navbarActive ? 'd-none' : ""}`}>
                                {/* <Link to='/' className={`ev-header__fixed-logo `}> */}

                                <img src={logoBlack} className="innerlogo" />
                            </Link>
                            <div className="row h-100 mx-auto">
                                <div className="col-md-12">
                                    <div id="site-header-inner" className="h-100">
                                        <div id="site-logo" className="clearfix">
                                            <div id="site-logo-inner">
                                                <Link to='/'>
                                                    <img src={navbarActive ? logoBlack : logo} className={`${enableHeaderLogo ? 'ev-header__logo--view' : 'ev-header__logo--hide'} ${navbarActive ? "ev-logo__visible" : ""}`} />
                                                </Link>
                                            </div>
                                            {/* <div>
                                        <p>BETA</p>
                                    </div> */}
                                        </div>

                                        <nav id="main-nav" className="main-nav">
                                            <ul id="menu-primary-menu" className="menu">
                                                {/* <li className="menu-item">
                                            <NavLink to="/">Home</NavLink>
                                        </li> */}




                                                <li className="menu-item menu-item-has-children ">
                                                    <NavLink to="/explore">Explore</NavLink>
                                                    <ul className="sub-menu">
                                                        <li className={"menu-item"} onClick={() => { click("All") }}><Link to="#">All</Link></li>

                                                        {Categorys.map(item => <li className={"menu-item"} onClick={() => { click(item.value) }}><Link to="#">{item.label}</Link></li>)}

                                                    </ul>

                                                </li>
                                                {/* {wallet && wallet.accountAddress &&
                                                    <li className="menu-item">
                                                        <NavLink to="/create">Create</NavLink>
                                                    </li>} */}
                                                <li className="menu-item">
                                                    <NavLink to="/faq">FAQ</NavLink>
                                                </li>
                                                <li className="menu-item">
                                                    <NavLink to="/launchpad">Launchpad</NavLink>
                                                </li>
                                                <li className="menu-item">
                                                    <NavLink to="/resale">Resale</NavLink>
                                                </li>
                                                {wallet && wallet.accountAddress &&
                                                    <li className="menu-item">

                                                        <NavLink to={`/profile/${payload?.CustomUrl}`}>Profile</NavLink>

                                                    </li>
                                                }
                                                <li className="menu-item search-item">
                                                    <form action="#">
                                                        <div className="search-form d-flex justify-content-between align-items-center">
                                                            <input type="text" value={val} placeholder="Search" required="" autoComplete="off" onChange={(e) => { OnChange(e.target.value); setSearchBottom(true) }} />
                                                            <Link className="btn-search">
                                                                {!val && <i className="icon-fl-search-filled"></i>}
                                                                {val && <i className="fa fa-times" onClick={() => { Setval(''); SetSearch(null) }}></i>}
                                                            </Link>
                                                            {/* <ul className="sub-menu d-block">
                                                        <li className="menu-item "><NavLink to="/explore">3D MODEL</NavLink></li>
                                                        <li className="menu-item"><NavLink to="/explore">ANIME/MANGA</NavLink></li>
                                                        <li className="menu-item"><NavLink to="/explore">CYBER PUNK</NavLink></li>
                                                        <li className="menu-item"><NavLink to="/explore">PIXEL ART</NavLink></li>
                                                        <li className="menu-item"><NavLink to="/explore">MUSIC</NavLink></li>
                                                        <li className="menu-item"><NavLink to="/explore">2D ARTS</NavLink></li>
                                                        <div class="btn-slider "><NavLink class="tf-button style-2" href="/">Search <i class="far fa-long-arrow-right"></i></Link></div>
                                                    </ul> */}
                                                        </div>

                                                        <div className={searchbottom && searchbottom ? "search-model" : "search-model kr_align"}>
                                                            {val && (
                                                                <div className="contentTop">
                                                                    {Searchdata?.token?.data?.length > 0 ||
                                                                        Searchdata?.user?.msg?.length > 0 || Searchdata?.collection?.msg?.length > 0 ? (<>
                                                                            {Searchdata?.collection?.data?.length > 0 && (
                                                                                <div className="content">
                                                                                    {/* <h6>Collections</h6> */}
                                                                                    {Searchdata?.collection?.data?.map(
                                                                                        (value) => (<>
                                                                                            {/* <div className="d-flex align-items-center justify-content-start pt-4 searchCursor" onClick={() => {
                                                                        Setval("");
                                                                        push(
                                                                            `/collection/${value.CollectionSymbol}`
                                                                        );
                                                                    }}
                                                                    >
                                                                       <div className="">
                                                                       <img
                                                                                        src={
                                                                                            value.CollectionProfileImage
                                                                                                ? `${Config.IMG_URL}/collection/${value.CollectionSymbol}/${value.CollectionProfileImage}`
                                                                                                : require("../assets/images/noimage.png")
                                                                                        }
                                                                                        
                                                                                     alt="Search" className="img-fluid"/>
                                                                       </div>
                                                                       <div className="ml-3">
                                                                        <p>{value.CollectionName}</p>
                                                                    
                                                                        
                                                                        </div>
                                                                    </div> */}
                                                                                        </>
                                                                                        )
                                                                                    )}
                                                                                </div>
                                                                            )}

                                                                            {Searchdata.user?.msg?.length > 0 && (

                                                                                <div className="content">
                                                                                    <h6>Users</h6>
                                                                                    {Searchdata.user?.msg.map(
                                                                                        (value) => (
                                                                                            <>
                                                                                                <div className="d-flex align-items-center justify-content-start pt-4 searchCursor"
                                                                                                    onClick={() => {
                                                                                                        Setval("");
                                                                                                        push(
                                                                                                            `/profile/${value.CustomUrl}`
                                                                                                        );
                                                                                                    }}
                                                                                                >
                                                                                                    {console.log("value", value)}                                                                                              <div className="search_profile_wrap">
                                                                                                        <img
                                                                                                            src={
                                                                                                                value.ProfilsearchCursore
                                                                                                                    ? `${Config.IMG_URL}/user/${value.WalletAddress}/profile/${value.Profile}`
                                                                                                                    : Config.profile
                                                                                                            }
                                                                                                            className=""

                                                                                                        />
                                                                                                    </div>
                                                                                                    <div className="ml-3">
                                                                                                        <p >
                                                                                                            {value.DisplayName}
                                                                                                        </p>
                                                                                                        <span></span>
                                                                                                    </div>
                                                                                                </div>
                                                                                            </>
                                                                                        )
                                                                                    )}
                                                                                </div>
                                                                            )}
                                                                            {Searchdata.token?.data?.length > 0 && (
                                                                                <div className="content">
                                                                                    <h6>Tokens</h6>
                                                                                    {Searchdata.token?.data.map(
                                                                                        (value) => (<>
                                                                                            <div className="d-flex align-items-center justify-content-start pt-4 searchCursor"
                                                                                                onClick={() => {
                                                                                                    Setval("");
                                                                                                    SetSearch();
                                                                                                    push(
                                                                                                        `/info/${value.CollectionNetwork}/${value.ContractAddress}/${value.NFTOwner}/${value.NFTId}`
                                                                                                    );
                                                                                                }}
                                                                                            >
                                                                                                <div className="search_profile_wrap">

                                                                                                    <img src={value.CompressedFile.includes('.webp') || value.CompressedFile.includes('.png') ? value.CompressedFile.split(':')[0] == 'https' ? value.CompressedFile : `${Config.IMG_URL}/nft/${value.NFTCreator}/Compressed/NFT/${value.CompressedFile}` : `${Config.IMG_URL}/nft/${value.NFTCreator}/Compressed/NFT_THUMB/${value.CompressedThumbFile}`} alt="Search" className="img-fluid" onError={event => {
                                                                                                        event.target.src = Config.IPFS + value.NFTOrginalImageIpfs
                                                                                                    }} />

                                                                                                </div>
                                                                                                <div className="ml-3">
                                                                                                    <p>  {value.NFTName}</p>
                                                                                                    <span></span>
                                                                                                </div>
                                                                                            </div>

                                                                                        </>
                                                                                        )
                                                                                    )}
                                                                                </div>

                                                                            )}
                                                                            <div className="tf-buton search-button my-5 d-flex justify-content-center">
                                                                                {/* <Link to="/">Search</Link>                                                         */}
                                                                                <Link to={`/search/${val}`} onClick={Search}>Search</Link>
                                                                            </div>
                                                                        </>) : (val === '' ? <></>
                                                                            : <span className="error_msg">
                                                                                No data Found
                                                                            </span>
                                                                    )}
                                                                </div>
                                                            )}
                                                        </div>

                                                    </form>
                                                </li>
                                            </ul>
                                        </nav>

                                        <div className="header-right ev-header__right">
                                            {wallet && wallet.accountAddress ?
                                                <NavLink to="#" className="tf-button tf-connect" onClick={() => { walletDisconnect() }}><span>Disconnect</span></NavLink>
                                                :
                                                <NavLink to="/connect" className="tf-button tf-connect co-wa"><span>Connect Wallet</span></NavLink>
                                            }
                                            {/* <NavLink to="/connect" className="tf-button tf-connect"><span>Connect Wallet</span></NavLink> */}
                                            {
                                                wallet && wallet.accountAddress &&
                                                <div className="popup-user">
                                                    <div className="avatar">
                                                        <img className="img-fluid imgprofile ml-3 ml-xl-5" width="20" height="18" src={payload?.Profile ? Config.IMG_URL + "/user/" + payload?.WalletAddress + '/profile/' + payload?.Profile : Config.profile} alt="avatar" />
                                                    </div>
                                                    <div className="avatar_popup">
                                                        {payload && payload.msg && payload.msg.DisplayName &&
                                                            <h6>{payload.msg.DisplayName}</h6>
                                                        }

                                                        <div className="price">

                                                            <p className="title">Balance</p>
                                                            {
                                                                wallet && wallet.coinBalance ?
                                                                    <p className="style">{wallet && wallet.coinBalance} {"SOL"}</p>
                                                                    :
                                                                    <p className="style">0 {network[Network]?.currencySymbol}</p>
                                                            }

                                                        </div>
                                                        {wallet && wallet.accountAddress && <>
                                                            {localStorage.getItem("walletConnectType") === 'Coinbase' ? <p className="title">Trust Wallet</p> : <p className="title">{localStorage.getItem("walletConnectType")}</p>}
                                                            <div className="code">
                                                                <p>{address_showing(wallet.accountAddress)}</p>
                                                                <CopyToClipboard
                                                                    text={wallet?.accountAddress}
                                                                    onCopy={() =>
                                                                        toast.success("Address Copied", { autoClose: 1000 })
                                                                    } >

                                                                    <img src={Copy} alt="Images" />

                                                                    {/* <i className="fas fa-sticky-note notes_fa cur_pointer"></i> */}
                                                                </CopyToClipboard>

                                                            </div>
                                                        </>}
                                                        {/* <ul className="links"> */}

                                                        {/* <li>
                                                        <NavLink to={`/profile/${payload?.CustomUrl}`}>
                                                            <img src={Profile} alt="images" />
                                                           
                                                            &emsp;<span>My Profile</span>
                                                        </NavLink>
                                                    </li> */}

                                                        {/* <li>
                                                    <NavLink to="/connect" className="mt-10">
                                                    <img src={WalletIcon} alt="images" />
                                                    &emsp;<span>Wallet</span>
                                                    </NavLink>
                                                </li> */}
                                                        {/* <li>
                                                    {wallet && wallet.accountAddress ?
                                                    <NavLink to="#" className="mt-10" id="logout" onClick={()=>{walletDisconnect()}}>
                                                    <img src={LogoutIcon} alt="images" />
                                                        &emsp;<span>Log out</span>
                                                    </NavLink>
                                                    :
                                                    <NavLink to="/connect" className="mt-10" id="logout">
                                                    <img src={LogoutIcon} alt="images" />
                                                        &emsp;<span>Log in</span>
                                                    </NavLink>
                                                    }
                                                </li> */}
                                                        {/* </ul> */}
                                                    </div>
                                                </div>
                                            }
                                            {/* <Link to="#" className="mode-switch" onClick={switchTheme}>
                                       <p className="icon-change"></p>
                                    </Link> */}

                                            <div
                                                className={`ev-header__right-menu ${menu == true ? "mobile-button active" : "mobile-button"} ${navbarActive ? 'ev-header__mobile-active' : 'ev-header__mobile-inactive'} `}
                                                id="mobile-button"
                                                onClick={() => setMenu(!menu)}>
                                                <span>
                                                </span>
                                            </div>
                                        </div>
                                        {/* <button className='ev-header__hamburger d-xl-none' onClick={handleShowMobileSidebar}><GiHamburgerMenu fontSize={20} /></button> */}
                                        {/* <div
                                            className={`${menu == true ? "mobile-button active" : "mobile-button"} ${navbarActive ? 'ev-header__mobile-active' : 'ev-header__mobile-inactive'} `}
                                            id="mobile-button"
                                            onClick={() => setMenu(!menu)}>
                                            <span>
                                            </span>
                                        </div> */}

                                    </div>
                                </div>
                            </div>
                        </div>
                    </header>

                    {updatePage && <>
                        <section className="tf-page-title" data-aos="fade-left">
                            <div className="tf-container">
                                <div className="row">
                                    <div className="col-md-12">
                                        <ul className="breadcrumbs">
                                            <li><NavLink to="/">Home</NavLink></li>
                                            {/* <li>Create profile</li> */}
                                        </ul>
                                    </div>
                                </div>
                            </div>
                        </section>
                        <BeforeloginEditProfile valData={dataa} />
                    </>}
                </>}

            {/* start of mobile */}
            <div className={`d-xl-none ev-header__menu-sidebar ${showMobileSidebar ? "active" : "inactive"} `}>
                <div className='ev-header__sidebar-top'>
                    <Link to='/'>
                        <img src={logoBlack} className='ev-header__sidebar--logo' />
                    </Link>
                    <button onClick={handleCloseMobileSidebar} className='ev-header__sidebar--close'>
                        <IoMdClose fontSize={25} />
                    </button>
                </div>
                <div className='ev-header__sidebar-menus '>
                    <ul>
                        <li>
                            Explore
                        </li>
                        {/* <li>
                            Create
                        </li> */}
                        <li>
                            FAQ
                        </li>
                        <li>
                            Profile
                        </li>
                    </ul>
                </div>
                <div>
                    <button className='ev-header__connectButton'>
                        <p>Connect Wallet</p>
                    </button>
                </div>
            </div>


        </>
    );
}