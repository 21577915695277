import React, { useState, useEffect, useRef } from "react";
import Card from "./Card.js";
import Header from "../separate/Header.js";
import Footer from "../separate/Footer.js";
import { NavLink, Link, useParams } from "react-router-dom";
import { useSelector } from "react-redux";
import { Token_List_Func } from "../actions/axioss/nft.axios";
import Button from "react-bootstrap/esm/Button.js";
import NoData from "./seperatemodals/nodata.jsx";
import { MdKeyboardArrowRight } from "react-icons/md";
import NFTcard from "../ cards/NFTcard.js";

import auction1 from "../assets/evolvex/images/auction1.gif";
import auction2 from "../assets/evolvex/images/auction2.gif";
import auction3 from "../assets/evolvex/images/auction3.png";
import auction4 from "../assets/evolvex/images/auction4.png";
import auction5 from "../assets/evolvex/images/auction5.png";
import auction6 from "../assets/evolvex/images/auction6.png";
import auction7 from "../assets/evolvex/images/auction7.png";
import auction8 from "../assets/evolvex/images/auction8.png";
import auction9 from "../assets/evolvex/images/auction9.png";
import auction10 from "../assets/evolvex/images/auction10.png";
import EvFooter from "../separate/EvFooter.js";

const hotAuctionCardData = [
  {
    id: 1,
    profileOne: auction9,
    profileTwo: auction10,
    banner: auction1,
  },
  {
    id: 2,
    profileOne: auction9,
    profileTwo: auction10,
    banner: auction2,
  },
  {
    id: 3,
    profileOne: auction9,
    profileTwo: auction10,
    banner: auction3,
  },
  {
    id: 4,
    profileOne: auction9,
    profileTwo: auction10,
    banner: auction4,
  },
  {
    id: 5,
    profileOne: auction9,
    profileTwo: auction10,
    banner: auction5,
  },
  {
    id: 6,
    profileOne: auction9,
    profileTwo: auction10,
    banner: auction6,
  },
  {
    id: 7,
    profileOne: auction9,
    profileTwo: auction10,
    banner: auction7,
  },
  {
    id: 8,
    profileOne: auction9,
    profileTwo: auction10,
    banner: auction8,
  },
];

export default function Explore() {
  const [loadingstate, setLoadingstate] = useState(true);
  const [category, setCategory] = React.useState(1);
  const [show, setShow] = React.useState(false);
  const [filter, setFilter] = useState("recentcreated");
  const [SubCategory,SetSubCategory]=useState("All")
  var [filtershow, setFiltershow] = useState("Recently Created");

  const { Categorys } = useSelector((state) => state.LoginReducer);
  const { payload } = useSelector((state) => state.LoginReducer.User);
  const { Category } = useParams();

  console.log("category", payload, Category, Categorys);

  const [TabName, SetTabName] = useState("All");
  var LikeForwardRef = useRef();
  const [LikedTokenList, setLikedTokenList] = useState([]);
  const [Tokens, SetTokens] = useState(
  //   {
  //   All: { loader: true, page: 1, list: [] },
  // }
  {
    All: {
      All: { loader: true, page: 1, list: [] },
    },
  }
  );
  const [TokensSub, SetTokensSub] = useState({
    All: { loader: true, page: 1, list: [] },
  });
  const Tabname = (newValue) => {
    SetTabName(newValue);
    if(newValue == "All"){
      SetSubCategory("All")
    }
  };
  useEffect(() => {
    if (Category) {
      if (Category !== TabName) {
        SetTabName(Category);
        Explore(1, Category,SubCategory);
      }
    } else {
      Explore();
    }

  }, [Category,SubCategory]);

  // useEffect(() => {
  //   // if (typeof Tokens[TabName] == "undefined") {
  //   if (
  //     typeof Tokens[TabName] == "undefined" ||
  //     Tokens[TabName].filter !== filter
  //   ) {
  //     Tokens[TabName] = { page: 1, list: [], loader: false };
  //     SetTokens(Tokens);
  //     Explore(1, TabName);
  //   }
  // }, [TabName, filter]);

  useEffect(() => {
    if (
      typeof Tokens[TabName]?.[SubCategory] === "undefined" ||
      Tokens[TabName][SubCategory].filter !== filter
    ) {
      if (!Tokens[TabName]) {
        Tokens[TabName] = {}; // Initialize category if undefined
      }
      Tokens[TabName][SubCategory] = { page: 1, list: [], loader: false }; // Initialize subcategory
      SetTokens({ ...Tokens });
      Explore(1, TabName, SubCategory); // Fetch for the updated category and subcategory
    }
  }, [TabName, SubCategory, filter]);
  
  const Explore = async (data, tab, subCategory = SubCategory) => {
    setLoadingstate(true);
  
    const page =
      data ?? Tokens[tab]?.[subCategory]?.page ?? 1; // Handle dynamic subcategory
    const SendDATA = {
      TabName: tab || TabName,
      TabNamesSub: subCategory,
      SaleResale: "Sale",
      limit: 12,
      ProfileUrl: payload?.ProfileUrl || "",
      page: page,
      from: "Explore",
      filter: filter,
    };
    console.log("Sending DATA", SendDATA);
  
    const Resp = await Token_List_Func(SendDATA);
    console.log("Response Data", Resp);
  
    if (Resp?.data) {
      SetTokens({
        ...Tokens,
        [tab]: {
          ...(Tokens[tab] || {}),
          [subCategory]: {
            list: [
              ...(Tokens[tab]?.[subCategory]?.list || []),
              ...Resp.data,
            ],
            loader:
              Resp.data.length === 0 ||
              (SendDATA.TabName === "usercollection" && Resp.cursor == null)
                ? false
                : true,
            page: page,
            filter: filter,
          },
        },
      });
    } else {
      SetTokens({
        ...Tokens,
        [tab]: {
          ...(Tokens[tab] || {}),
          [subCategory]: {
            list: Tokens[tab]?.[subCategory]?.list || [],
            loader: false,
            page: page,
            filter: filter,
          },
        },
      });
    }
    setLoadingstate(false);
  };
  
  const LoadMore = () => {
    const currentPage = Tokens[TabName]?.[SubCategory]?.page ?? 1;
    Tokens[TabName][SubCategory].page = currentPage + 1;
    SetTokens({ ...Tokens });
    Explore(currentPage + 1, TabName, SubCategory);
  };

  // const Explore = async (data, tab) => {
  //   setLoadingstate(true);

  //   var page = data ? data : Tokens[TabName]?.page;
  //   var SendDATA = {
  //     TabName: tab ? tab : TabName,
  //     TabNamesSub:SubCategory,
  //     limit: 12,
  //     ProfileUrl: payload?.ProfileUrl ? payload.ProfileUrl : "",
  //     page: page ?? 1,
  //     from: "Explore",
  //     filter: filter,
  //   };
  //   console.log("SendsdasdaDATA", SendDATA);
  //   let Resp = await Token_List_Func(SendDATA);
  //   console.log("Check datad", Resp);
  //   if (Resp?.data) {
  //     console.log("TokensTokensTokensTokens",Tokens);
  //     SetTokens({
  //       ...Tokens,
  //       ...{
  //         [TabName]: {
  //           list: [...Tokens[TabName].list, ...Resp.data],

  //           loader:
  //             Resp.data.length == 0 ||
  //             (SendDATA.TabName == "usercollection" && Resp.cursor == null)
  //               ? false
  //               : true,
  //           page: Tokens[TabName].page,
  //           filter: filter,
  //         },
  //       },
  //     });
  //     setLoadingstate(false);
  //   } else
  //     SetTokens({
  //       ...Tokens,
  //       ...{
  //         [TabName]: {
  //           list: Tokens[TabName].list,
  //           loader: false,
  //           page: Tokens[TabName].page,
  //           filter: filter,
  //         },
  //       },
  //     });
  //   setLoadingstate(false);
  // };
  // const LoadMore = () => {
  //   Tokens[TabName].page = Tokens[TabName].page + 1;
  //   SetTokens(Tokens);
  //   Explore(Tokens[TabName].page);
  // };
  function LikeList(data) {
    setLikedTokenList(data);
  }

  // useEffect(() => {
  //   if (Category) {
  //     console.log("VALUE",Category);
  //     SetTabName(Category)
  //     Explore(1, Category)
  //   }
  //   else {
  //     Explore();
  //   }
  // }, [Category]);

  console.log("tokeokokeokoekoeke", Tokens);

  return (
    <>
      <Header />
      <div className="explore">
        <section className="tf-page-title" data-aos="fade-right">
          {/* <div className="tf-container"> */}
          <div className="container container-width">
            <div className="row">
              <div className="col-md-12">
                <ul className="breadcrumbs">
                  <li>
                    <NavLink to="/">
                      Home <MdKeyboardArrowRight />
                    </NavLink>
                  </li>
                  <li>Explore</li>
                </ul>
                <h4 className="page-title-heading">Explore</h4>
              </div>
            </div>
          </div>
        </section>
        <section
          className="tf-live-auction explore tf-filter exploreinnersec"
          data-aos="fade-left"
        >
          {/* <div className="tf-container"> */}
          <div className="container container-width">
            <div className="row ">
              <div className="col-md-12">
                <div
                  className="top-menu ev-explore__top-menu wow fadeInUp explore_category_section"
                  data-aos="fade-up"
                >
                  <ul className="filter-menu">
                    <Link to="/explore">
                      <li
                        className={TabName == "All" ? "active" : ""}
                        onClick={() => Tabname("All")}
                      >
                        All
                      </li>
                    </Link>
                    {Categorys.map((item) => {
                      return (
                        <Link to="/explore">
                          <li
                            className={TabName == item.label ? "active" : ""}
                            onClick={() => Tabname(item.label)}
                          >
                            <a>{item.label}</a>
                          </li>
                        </Link>
                      );
                    })}
                    {/* <li className={category == 1 ? "active" : ""} onClick={() => setCategory(1)}><a>3D MODEL</a></li>
                                    <li className={category == 2 ? "active" : ""} onClick={() => setCategory(2)}><a>ANIME/MANGA</a></li>
                                    <li className={category == 3 ? "active" : ""} onClick={() => setCategory(3)}><a>CYBER PUNK</a></li>
                                    <li className={category == 4 ? "active" : ""} onClick={() => setCategory(4)}><a>PIXEL ART</a></li>
                                    <li className={category == 5 ? "active" : ""} onClick={() => setCategory(5)}><a>MUSIC</a></li>
                                    <li className={category == 6 ? "active" : ""} onClick={() => setCategory(6)}><a>ABSTRACT</a></li>
                                    <li className={category == 7 ? "active" : ""} onClick={() => setCategory(7)}><a>2D ARTS</a></li> */}
                  </ul>
                  <div id="item_category2" className="dropdown">
                    <Link to="#" className="btn-selector nolink allshow">
                      {filtershow}
                    </Link>
                    <ul className="show">
                      <li
                        onClick={() => {
                          setFilter("recentlisted");
                          setFiltershow("Recently Listed");
                        }}
                      >
                        <span>Recently Listed</span>
                      </li>
                      <li
                        className="active"
                        onClick={() => {
                          setFilter("recentcreated");
                          setFiltershow("Recently Created");
                        }}
                      >
                        <span>Recently Created</span>
                      </li>
                      {/* <li
                        onClick={() => {
                          setFilter("recentsold");
                          setFiltershow("Recently Sold");
                        }}
                      >
                        <span>Recently Sold</span>
                      </li> */}
                      <li
                        onClick={() => {
                          setFilter("PriceHighToLow");
                          setFiltershow("High to Low");
                        }}
                      >
                        <span>High to Low</span>
                      </li>
                      <li
                        onClick={() => {
                          setFilter("PriceLowToHigh");
                          setFiltershow("Low to High");
                        }}
                      >
                        <span>Low to High</span>
                      </li>
                      <li
                        onClick={() => {
                          setFilter("oldest");
                          setFiltershow("Oldest");
                        }}
                      >
                        <span>Oldest</span>
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
              {Categorys.length > 0 &&
                Categorys.map((item) => {
                  return (
                    <>
                      {item.label == TabName && (
                        <>
                          <div className="col-md-12 pt-2">
                            <p className="ev-category__desc">
                              {item.description}
                            </p>
                          </div>
                          <div className="col-md-12 py-4">
                            <div className="ev-explore__subcategory">
                              {item.BulkSubCategory &&
                                item.BulkSubCategory.length > 0 &&
                                item.BulkSubCategory.map((item1) => {
                                  return (
                                    <button
                                    // className= {"ev-explore__subcat-button" + " " + SubCategory == item.name ? "active" : ""}
                                    className= {`ev-explore__subcat-button ${SubCategory == item.name ? "active" : ""}`}

                                    onClick={() => SetSubCategory(item1.name)}>
                                      {item1.name}
                                    </button>
                                  );
                                })}
                            </div>
                          </div>
                        </>
                      )}
                    </>
                  );
                })}
            </div>
            {console.log("TokensTokensTokensTokensTokens",Tokens,TabName,SubCategory)}
            <div className="tf-filter-container row mt-5" data-aos="fade-up">
              {/* {category == 1 ? */}
              {/* {console.log('likrreeeeexple',LikeForwardRef,setLikedTokenList,LikedTokenList)} */}
              {loadingstate == true ? (
                <>
                  <div className="text-centre">
                    <h3>Loading...</h3>
                    {/* {/ <p>Come back soon ! or try to browse something for you on our marketplace</p> /} */}
                    {/* <p>Nothing for ReSale at this time</p> */}
                    <div className="load-more">
                      {/* <Link to='/'><Button type="type" className="btn-load browse_marketplace" disableRipple>Browse Marketplace</Button></Link> */}
                    </div>
                  </div>
                </>
              ) : // start of old code

              // TabName && Tokens[TabName]?.list.length > 0 ? (
              //   Tokens[TabName]?.list.map((item, index) => (
              //     <div className="col-xl-3 col-lg-4 col-md-6 col-sm-6 tf-loadmore 3d cyber">
              //       {console.log("datainexplore", item)}
              //       <Card
              //         product={item}
              //         index={index}
              //         LikeList={LikeList}
              //         LikedTokenList={LikedTokenList}
              //         setLikedTokenList={setLikedTokenList}
              //         LikeForwardRef={LikeForwardRef}
              //       />
              //     </div>
              //   ))
              // )

              // TabName && Tokens[TabName]?.list.length > 0 ? (
              //   Tokens[TabName]?.list.map((item, index) => (
                Tokens[TabName]?.[SubCategory]?.list?.length > 0 ? (
                  Tokens[TabName][SubCategory].list.map((item, index) => (
        
                  <div className="d-flex justify-content-center justify-content-lg-start col-md-6 col-lg-4 col-xl-3 mb-5">
                    {console.log("datainexplore", item)}
                    <Card
                      product={item}
                      index={index}
                      LikeList={LikeList}
                      LikedTokenList={LikedTokenList}
                      setLikedTokenList={setLikedTokenList}
                      LikeForwardRef={LikeForwardRef}
                    />
                  </div> 
            
                  
                ))
              ) : (
                // start of evolvex code

                // TabName && Tokens[TabName]?.list.length > 0 ? (
                //   Tokens[TabName]?.list.map((item, index) => (
                //     //     <div className="col-xl-3 col-lg-4 col-md-6 col-sm-6 tf-loadmore 3d cyber">
                //     hotAuctionCardData.map((item) => (
                //       <div className='col-xl-3 col-lg-4 col-md-6 col-sm-6 tf-loadmore 3d cyber mb-5'>
                //         <NFTcard item={item} />
                //       </div>
                //     ))
                //   ))
                // )

                // end of evolvex code

                <NoData />
              )}
            </div>
            {/* {console.log('gfbvfbfb',Tokens,Tokens[TabName]?.loader)} */}
            {Tokens[TabName]?.[SubCategory]?.loader && (
              <div className="d-flex justify-content-center">
                {/* <div className="btn-loadmore mt17">
                  <Button className="tf-button loadmore" onClick={LoadMore}>
                    Load More
                  </Button>
                </div> */}
                <button onClick={LoadMore} className=" ev_bannerBlueBtn my-5">
                  Load More
                </button>
              </div>
            )}
          </div>
        </section>
      </div>
      {/* <Footer /> */}
      <EvFooter />
    </>
  );
}
