import { Route, Routes, Redirect } from "react-router-dom";
import Home from "./views/Home";
import Explore from "./views/Explore";
import FAQ from "./views/FAQ";

import Blog from "./views/Blog";
import Blogdetail from "./views/Blogdetail";

import Create from "./views/Create";
import Collection from "./views/Collection";
import ConnectWallet from "./views/ConnectWallet";
import ContactUs from "./views/Contactus";
import Details from "./views/Details";
import Board from "./views/Board";
import PrivacyPolicy from "./views/PrivacyPolicy";
import Referraldetail from "./views/referraldetail";
import MyItem from "./views/my_item";
import CollectionList from "./views/CollectionList";
import Search from "./views/Search";

import { BrowserRouter } from 'react-router-dom';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import Activity from "./views/Activity";

import List from "./views/List";

import AboutUs from "./views/Aboutus";
import Referral from "./views/Referral";
import HelpandCentre from "./views/helpandcentre";
import CreateProfile from './views/BeforeloginEditProfile';
import Comingsoon from './views/comingsoon';
import Landing from "./views/Landing";
import Launchpad from "./views/Launchpad";
import Resale from "./views/Resale";
import LaunchpadDetails from "./views/LaunchpadDetails";

function App() {


  return (
    <>
      <BrowserRouter basename="/">
        <Routes>
          {/* completed routes */}
          <Route path='/' element={<Landing />} />
          <Route path='/explore' element={<Explore />} />
          <Route path='/launchpad' element={<Launchpad />} />
          <Route path='/launchpad-details/:network/:Contract/:Owner/:Id' element={<LaunchpadDetails />} />
          <Route path='/resale' element={<Resale />} />
          <Route path='/faq' element={<FAQ />} />
          <Route path='/blog' element={<Blog />} />
          <Route path='/create' element={<Create />} />
          <Route path='/connect' element={<ConnectWallet />} />
          <Route path='/privacy-policy' element={<PrivacyPolicy />} />
          {/* <Route path='/details' element={<Details />} /> */}
          <Route path='/blogdetail/:url' element={<Blogdetail />} />
          <Route path='/info/:network/:Contract/:Owner/:Id' element={<Details />} />
          <Route path='/profile/:customurl' element={<Board />} />
          <Route path='/createProfile/:walletAddress' element={<CreateProfile />} />
          <Route path='/connect/:referralLink' element={<ConnectWallet />} />
          <Route path='/explore/:Category' element={<Explore />} />
          <Route path='/aboutus' element={<AboutUs />} />

          <Route path="/my-item/:customurl" element={<MyItem />} />
          {/* <Route path='/contactus' element={<ContactUs />} /> */}
          <Route path='/list/:address/:id' element={<List />} />

          <Route path='/Activity' element={<Activity />} />
          <Route path='/referraldetail' element={<Referraldetail />} />
          <Route path='/helpandcentre' element={<HelpandCentre />} />
          {/* completed routes */}

          <Route path='/collection/:CollectionSymbol' element={<Collection />} />
          <Route path='/search' element={<Search />} />
          <Route path='/collectionlist' element={<CollectionList />} />
          <Route path="/myitem" element={<MyItem />} />
          <Route path='/search/:key' element={<Search />} />
          {/* <Route path='/' element={<Home />} /> */}
          {/* <Route path='/privacypolicy' element={<PrivacyPolicy />} /> */}


        </Routes>

      </BrowserRouter>

      <ToastContainer
        position="top-right"
        autoClose={1000}
        hideProgressBar={false}
        rtl={true}
        closeOnClick={true}
        closeButton={true}
        style={
          {
            zIndex: "999999",
            "width": "400px",
            "wordBreak": "break-word",
            "text-align": "center",
            "fontWeight": "500",
            marginTop: "70px"
          }}
      />
    </>
  );
}

export default App;
