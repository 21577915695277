
/** npm import */
import React, { useEffect } from 'react'
import { MdVerified } from "react-icons/md";

/** local file import */
import copyImage from '../assets/evolvex/images/copy.png'
import Aos from 'aos';
import tickicon from '../assets/evolvex/images/tickicon.png'
import Copy from "../assets/images/svg/copy.svg";
import { Link } from 'react-router-dom';
import config from "../views/config/config";
import CopyToClipboard from "react-copy-to-clipboard";
import { toast } from 'react-toastify';
const CreatorCard = (props) => {
    const { id, Profile, bannerImage, DisplayName, address, CustomUrl, WalletAddress } = props.item
    console.log(DisplayName, 'propsss')
    useEffect(() => {
        Aos.init();
    }, [])
    return (

        // <div className='ev-creatorcard' data-aos="fade-up" data-aos-offset="300">
        //     <Link to={`/profile/${CustomUrl}`} >
        //         <>
        //             <div className='ev-creatorcard__banner'>
        //                 <img src={Profile
        //                     ? `${config.IMG_URL}/user/${WalletAddress}/profile/${Profile}`
        //                     : config.profile} alt={DisplayName} />
        //             </div>
        //             <div className='ev-creatorcard__profile'>
        //                 <div className='ev-creatorcard__profile--wrap'>
        //                     <img src={Profile
        //                         ? `${config.IMG_URL}/user/${WalletAddress}/profile/${Profile}`
        //                         : config.profile} alt={DisplayName} className='ev-fit__image--cover' />
        //                 </div>
        //             </div>
        //         </>
        //     </Link>
        //     <div className='ev-creatorcard__content d-flex flex-column align-items-center'>
        //         <div className='ev-creatorcard__name'>
        //             <h5>{DisplayName}</h5>
        //             <div className='ev-verified'>
        //                 <img src={tickicon} alt='copy' />
        //             </div>
        //         </div>
        //         <div className='ev-creatorcard__address'>
        //             <p>{WalletAddress.slice(0, 10) + '...'}</p>
        //             <CopyToClipboard
        //                 text={WalletAddress}
        //                 onCopy={() =>
        //                     toast.success("Address Copied", { autoClose: 1000 })
        //                 } >

        //                 <img src={copyImage} alt='copy' />

        //                 {/* <i className="fas fa-sticky-note notes_fa cur_pointer"></i> */}
        //             </CopyToClipboard>
        //             {/* <button className='ev-creatorcard__button--copy'>
        //                 <img src={copyImage} alt='copy' />
        //             </button> */}
        //         </div>
        //     </div>
        // </div>
        <Link to={`/profile/${CustomUrl}`} >
        <div
            className='ev-daoscard'
            data-aos="fade-up"
            data-aos-offset="300">
            <div className='ev-daoscard__imgwrapper'>
                <img src={Profile
                    ? `${config.IMG_URL}/user/${WalletAddress}/profile/${Profile}`
                    : config.profile} alt={DisplayName} />
            </div>

            <div className='ev-daos__details mt-4 d-flex flex-column align-items-center'>
                <div className='ev-creatorcard__name'>
                    <h5>{DisplayName}</h5>
                    <div className='ev-verified'>
                        <img src={tickicon} alt='copy' />
                    </div>
                </div>
                <div className='ev-creatorcard__address'>
                <p>{WalletAddress.slice(0, 10) + '...'}</p>
        <CopyToClipboard
            text={WalletAddress}
            onCopy={() =>
                toast.success("Address Copied", { autoClose: 1000 })
            } >

            <img src={copyImage} alt='copy' />

            {/* <i className="fas fa-sticky-note notes_fa cur_pointer"></i> */}
        </CopyToClipboard>
                    {/* <button className='ev-creatorcard__button--copy'>
                        <img src={copyImage} alt='copy' />
                    </button> */}
                </div>
            </div>

        </div>
        </Link>

    )
}

export default CreatorCard