import React from 'react'
import { Modal } from 'react-bootstrap'
import ImgAudVideo from '../separate/ImgAudVideo'

import ReactPlayer from 'react-player'

const Demo = ({ show, handleClose }) => {
    return (
        <Modal
            size="lg"
            // aria-labelledby="contained-modal-title-vcenter"
            centered
            show={show}
            className='ev-model__lg'
        >
            <Modal.Header>
                <button
                    type="button"
                    class="btn-close"
                    aria-label="Close"
                    onClick={handleClose}
                ></button>
                <Modal.Title id="contained-modal-title-vcenter">
                    Follow Steps
                </Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <div className='ev-react__video'>
                    <ReactPlayer
                        url='https://www.youtube.com/watch?v=LXb3EKWsInQ'
                        playing={true}
                        loop={true}
                        controls={true}
                        width="100%"
                    />

                    {/* <iframe
                        width="860"
                        height="484"
                        src="https://www.youtube.com/watch?v=LXb3EKWsInQ"
                    ></iframe> */}
                </div>
            </Modal.Body>
        </Modal>
    )
}

export default Demo